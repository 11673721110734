import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// ---------------------
// EVENTS TRACKED
// ---------------------
//
// BASIC
// ---------------------
// page_view            --> fires on all page loads
// login                --> fires when user logs in
// signup               --> fires when user signs up
//
// Consults
// ---------------------
// consult_created
// consult_submitted
// consult_paid
// consult_reviewed
// consult_inreview
// consult_approved
// consult_declined
// consult_cancelled

// Rx
// ---------------------
// rx_created
// rx_awaiting_payment
// rx_paid
// rx_order_created

// Metadata for each event
// ---------------------
// --> user ID of the user who owns the consult
// --> ID of the consult/rx

mixpanel.init('a27e327106d3ae0a2d87cb864825f5f5', { debug: true });
// mixpanel.init('a27e327106d3ae0a2d87cb864825f5f5');

// Mixpanel exports
export const mp = mixpanel;

// Customer hook to track page views
export function useTrackPageViews(router) {
  useEffect(() => {
    const handleRouteChange = (url) => {
      // console.log('XXXXXXXXXXXXXXXXXXX');
      // console.log(router.asPath);
      // console.log(document.referrer);
      // console.log('XXXXXXXXXXXXXXXXXXX');
      mp.track('page_view', {
        url,
      });
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // console.log('XXXXXXXXXXXXXXXXXXX');
    // console.log(router.asPath);
    // console.log(document.referrer);
    // console.log('XXXXXXXXXXXXXXXXXXX');
    mp.track('page_view', {
      page: document.title,
      domain: window.location.href,
      url: document.location.pathname,
    });
  }, []);

  return;
}
