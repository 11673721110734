import '../styles/globals.css';
import { UserContext } from '../lib/context';
import { useUserData } from '../lib/hooks';
import { Toaster } from 'react-hot-toast';
import ProtectedRoute from '../components/ProtectedRoute';
import { useTrackPageViews } from '../lib/mixpanel';
import Script from 'next/script';

function MyApp({ Component, pageProps, router }) {
  const userData = useUserData();
  useTrackPageViews(router);

  return (
    <>
      <Script
        id="google-analytics"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=GTM-TPVD5HP`}
      />

      <Script id="google-analytics2" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'GTM-TPVD5HP', {
              page_path: window.location.pathname,
            });
                `}
      </Script>

      <Script id="google-tag" strategy="lazyOnload">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TPVD5HP');`}
      </Script>

      <Script id="msclarity" strategy="lazyOnload">
        {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "dfedrw7sbs");`}
      </Script>

      <UserContext.Provider value={userData}>
        <ProtectedRoute router={router}>
          <Component {...pageProps} />
          <Toaster />
        </ProtectedRoute>
      </UserContext.Provider>
    </>
  );
}

export default MyApp;
