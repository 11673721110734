import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyC5gL0K_h1ufDSQ6ed0LRJ8BDtvVXyxJK0',
  authDomain: 'fauna-44ec3.firebaseapp.com',
  projectId: 'fauna-44ec3',
  storageBucket: 'fauna-44ec3.appspot.com',
  messagingSenderId: '539142294031',
  appId: '1:539142294031:web:b27c28a3a5a5a0c97bb17a',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Auth Exports
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Firestore Exports
export const fire = firebase;
export const firestore = firebase.firestore();
export const functions = firebase.functions();
// export const functions = firebase.functions().useEmulator('localhost', 5001);

export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const fromMillis = firebase.firestore.Timestamp.fromMillis;
export const deleteField = firebase.firestore.FieldValue.delete;
export const documentId = firebase.firestore.FieldPath.documentId;

// Storage Exports
export const storage = firebase.storage();

// Firestore Exports - Part 2
export const getItem = async (collection, uid) => {
  const presRef = await firestore.collection(collection).doc(uid).get();
  return presRef.data();
};
