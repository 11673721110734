// import { useAuthContext } from "../contexts";
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../lib/firebase';

// TUTORIALS
// https://jasonwatmore.com/post/2021/08/30/next-js-redirect-to-login-page-if-unauthenticated
// https://azeezatraheem.medium.com/implementing-authentication-redirects-in-next-js-c15907ec82b7
// https://splitbee.io/login

const ProtectedRoute = ({ children, router }) => {
  // Identify authenticated user
  const [user, loading, error] = useAuthState(auth);
  const [medicalUserAuth, setMedicalUserAuth] = useState(null);
  const [medicalUserAuthLoaded, setMedicalUserAuthLoaded] = useState(null);

  const isMedicalOnly = () => {
    let isProtected = false;
    if (router.pathname.includes('/medical')) {
      isProtected = true;
    }
    if (router.pathname.includes('/medical/auth')) {
      isProtected = false;
    }
    // console.log('-------------------');
    // console.log(isProtected);
    // console.log('-------------------');
    return isProtected;
  };

  const isMedicalProtected = isMedicalOnly();

  // Medical User
  useEffect(async () => {
    // console.log('=======================');
    // console.log(!loading);
    // console.log(user);
    // // console.log(isMedicalProtected);
    // console.log('=======================');
    if (!loading && user && isMedicalProtected) {
      const ref = await firestore.collection('medicals').doc(user.uid).get();
      const data = ref.data();
      // console.log('HELOO');
      // console.log(ref.exists);
      // console.log(data.isApproved);

      // get medical record and confirm it exists
      // check is isAuthorised = true
      if (ref.exists && data.isApproved) {
        setMedicalUserAuth(true);
      } else {
        router.push('/medical/auth/login');
      }
    } else {
      setMedicalUserAuth(false);
    }
  }, [loading]);

  // Medical User Loaded
  useEffect(() => {
    if (medicalUserAuth == true || medicalUserAuth == false) {
      setMedicalUserAuthLoaded(true);
    }
  }, [medicalUserAuth]);

  // console.log('ROUTER PATHNAME');
  // console.log(router.pathname);

  // useEffect(() => {
  //   if (user) {
  //     console.log('user...');
  //     console.log(user);
  //   }
  // }, [user]);

  let publicPaths = [
    '/',
    '/about',
    '/faq',
    '/start',
    '/birth-control',
    '/birth-control/register',
    '/acne',
    '/acne/register',
    '/login',
    '/forgot',
    '/privacy',
    '/medical/auth/reset',
    '/medical/auth/login',
    '/medical/auth/signup',
    '/medical/auth/forgot',
    '/reset',
    '/signup',
    '/started',
    '/terms',
  ];

  let publicPathsInclude = ['/r/', '/a', '/prep', '/sti-clinics', '/clinics'];
  let publicPathsIncluded = false;
  for (let path of publicPathsInclude) {
    publicPathsIncluded = router.pathname.includes(path);
  }

  let pathIsProtected =
    publicPaths.indexOf(router.pathname) === -1 && !publicPathsIncluded;

  // Regular User
  // If not logged in -- access to public routes only
  // If logged in -- access to all routes except medical routes
  // Logic --> If there is no logged in user and the path is not a public path, then kick out
  useEffect(() => {
    if (!loading && !user && pathIsProtected) {
      router.push('/login');
    }
  }, [loading]);

  // Medical User
  // If not logged in -- access to public routes only
  // If logged in -- access to all routes including medical routes
  // If there is no logged in user and the path is not a public path, then kick out

  useEffect(() => {
    // console.log('-------------------');
    // console.log(pathIsProtected);
    // console.log(isMedicalProtected);
    // console.log(!medicalUserAuth);
    // console.log(medicalUserAuth !== null);
    // console.log(medicalUserAuthLoaded);
    // console.log('-------------------');

    if (
      pathIsProtected &&
      isMedicalProtected &&
      !medicalUserAuth &&
      medicalUserAuth !== null &&
      medicalUserAuthLoaded
    ) {
      router.push('/medical/auth/login');
      // console.log('-------------------');
      // console.log('Redirected HERE');
      // console.log('-------------------');
    }
  }, [medicalUserAuth]);

  return children;
};

export default ProtectedRoute;

// // Auth check function
// function authCheck(url) {
//   // redirect to login page if accessing a private page and not logged in

// let publicPaths = [
//   '/',
//   '/about',
//   '/faq',
//   '/birth-control',
//   '/birth-control/register',
//   '/login',
//   '/forgot',
//   '/privacy',
//   '/reset',
//   '/signup',
//   '/started',
//   '/terms',
// ];
//   const path = url.split('?')[0];
//   console.log('hallo');
//   if (!user && !publicPaths.includes(path)) {
//     setAuthorized(false);
//     console.log('changing');
//     router.push('/login');
//   } else {
//     setAuthorized(true);
//   }
// }

// useEffect(() => {
//   // on initial load - run auth check
//   authCheck(router.asPath);

//   // on route change start - hide page content by setting authorized to false
//   const hideContent = () => setAuthorized(false);
//   router.events.on('routeChangeStart', hideContent);

//   // on route change complete - run auth check
//   router.events.on('routeChangeComplete', authCheck);

//   // unsubscribe from events in useEffect return function
//   return () => {
//     router.events.off('routeChangeStart', hideContent);
//     router.events.off('routeChangeComplete', authCheck);
//   };

//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

// let unprotectedRoutes = [
//   '/',
//   '/about',
//   '/faq',
//   '/login',
//   '/forgot',
//   '/privacy',
//   '/reset',
//   '/signup',
//   '/started',
//   '/terms',
// ];

// let pathIsProtected = unprotectedRoutes.indexOf(router.pathname) === -1;

// if (!isAuthenticated && pathIsProtected) {
//   router.push('/login');
// }
