import { auth, firestore, documentId } from '../lib/firebase';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useRouter } from 'next/router';

// Custom hook to read auth record and user profile doc
export function useUserData() {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [userConsults, setUserConsults] = useState(null);

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    if (user) {
      const ref = firestore.collection('users').doc(user.uid);
      unsubscribe = ref.onSnapshot((doc) => {
        setUserData(doc.data());
      });
    } else {
      setUserData(null);
    }

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    if (user) {
      const ref = firestore
        .collection('consultations')
        .where('userID', '==', user.uid)
        .orderBy('createdAt', 'desc');
      unsubscribe = ref.onSnapshot((querySnapshot) => {
        let consults = [];

        querySnapshot.forEach((doc) => {
          consults.push({ data: doc.data(), id: doc.id });
        });
        if (consults.length !== 0) {
          setUserConsults(consults);
        }
      });
    } else {
      setUserConsults(null);
    }

    return unsubscribe;
  }, [user]);

  return { user, userData, userConsults };
}

// Custom hook to read active consultation
export function useActiveConsultation() {
  const [user] = useAuthState(auth);
  const router = useRouter();
  const [activeConsultation, setActiveConsultation] = useState(null);

  // // turn off realtime subscription
  // useEffect(() => {
  //   let unsubscribe;

  //   if (user) {
  //     const ref = firestore
  //       .collection('consultations')
  //       .doc(router.query.consultationId);
  //     unsubscribe = ref.onSnapshot((doc) => {
  //       setActiveConsultation({ data: doc.data(), id: doc.id });
  //     });
  //   } else {
  //     setActiveConsultation(null);
  //   }

  //   return unsubscribe;
  // }, [user]);

  // // turn off realtime subscription
  // useEffect(() => {
  //   let unsubscribe;

  //   const ref = firestore
  //     .collection('consultations')
  //     .doc(router.query.consultationId);
  //   unsubscribe = ref.onSnapshot((doc) => {
  //     setActiveConsultation({ data: doc.data(), id: doc.id });
  //   });

  //   return unsubscribe;
  // }, []);

  // const active = firestore
  //   .collection('consultations')
  //   .doc(router.query.consultationId)
  //   .onSnapshot((doc) => {
  //     console.log('Current data: ', doc.data());
  //   });

  return { activeConsultation };
}

// Custom hook to read consults and users data for medical dashboard (/medical/view)
export function useConsultsForMedicalView() {
  const [usersData, setUsersData] = useState(null);
  const [userConsults, setUserConsults] = useState(null);

  // On initial load, get the consults
  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    const ref = firestore
      .collection('consultations')
      .limit(10)
      .orderBy('updatedAt', 'desc');

    unsubscribe = ref.onSnapshot((querySnapshot) => {
      let consults = [];
      querySnapshot.forEach((doc) => {
        consults.push({ ...doc.data(), id: doc.id });
      });
      setUserConsults(consults);
    });

    return unsubscribe;
  }, []);

  // When consults are loaded, get the users
  useEffect(async () => {
    if (userConsults) {
      // turn off realtime subscription
      let unsubscribe;

      const ids = userConsults.map((x) => x.userID);

      const ref = firestore.collection('users').where(documentId(), 'in', ids);

      unsubscribe = ref.onSnapshot((querySnapshot) => {
        let users = [];
        console.log('Fired - in');
        querySnapshot.forEach((doc) => {
          users.push({ ...doc.data(), id: doc.id });
        });

        const nn = userConsults.map((x, i) => {
          return users.find((user) => user.id == x.userID);
        });

        setUsersData(nn);
      });
      return unsubscribe;
    }
  }, [userConsults]);

  // // When consults are loaded, get the users
  // useEffect(async () => {
  //   if (userConsults) {
  //     // turn off realtime subscription
  //     let unsubscribe;

  //     const ids = userConsults.map((x) => x.userID);

  //     let arrays = [];
  //     const size = 10;

  //     for (let i = 0; i < ids.length; i += size) {
  //       arrays.push(ids.slice(i, i + size));
  //     }

  //     let users = [];
  //     for (let array of arrays) {
  //       const ref = firestore.collection('users');
  //       // .where(documentId(), 'in', array);

  //       unsubscribe = ref.onSnapshot((querySnapshot) => {
  //         console.log('Fired - in');
  //         querySnapshot.forEach((doc) => {
  //           users.push({ ...doc.data(), id: doc.id });
  //         });

  //         const nn = userConsults.map((x, i) => {
  //           return users.find((user) => user.id == x.userID);
  //         });

  //         if (!nn.includes(undefined)) {
  //           // console.log('USERS');
  //           // console.log(nn);
  //           setUsersData(nn);
  //         }
  //       });
  //     }

  //     return unsubscribe;
  //   }
  // }, [userConsults]);

  return { usersData, userConsults };
}
